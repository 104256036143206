<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row justify="center">
          <h5 class="title">Altualização de Ocorrência</h5>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <v-row justify="center">
                <v-col cols="4" class="text-right">
                  <v-checkbox
                    :disabled="disabled"
                    color="green"
                    v-model="secretariaCheck"
                    label="Secretaria"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="disabled"
                    color="green"
                    v-model="professorCheck"
                    label="Professor"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox :disabled="disabled" color="green" v-model="alunoCheck" label="Aluno"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined :rows="6" counter="2045" label="Descrição da ocorrência" :rules="[rules.length]" v-model="text" :disabled="disabled"></v-textarea>
            </v-col>
            <v-col cols="12"></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <label class="red--text">{{ message }}</label>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center" v-show="disabled">
              <v-progress-circular indeterminate color="red"></v-progress-circular>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn color="green" block outlined @click="updateOccurrence()">OK</v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn color="red" block outlined @click="closeUpdateDialog">Cancelar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { occurrenceUtils, users } from '@/utils/occurrenceUtils'

export default {
  props: ["item", "academicLifeId"],

  data() {
    return {
      occurrence: {},
      disabled: false,
      secretariaCheck: false,
      professorCheck: false,
      alunoCheck: false,
      message: "",
      text: "",
      academicLife: null,
      rules: {
        length: v => (v || '').length <= 2045 || 'O texto deve ser conter menos que 2045 caracteres!'
      }
    };
  },

  created() {
    this.occurrence = this.item;
    this.text = this.occurrence.text;
    this.academicLife = this.academicLifeId;
    this.updateCheckBox(this.occurrence.visibility);
  },

  watch: {
    item(newItem) {
      this.occurrence = newItem;
      this.text = this.occurrence.text;
      this.updateCheckBox(this.occurrence.visibility);
    },
    academicLifeId(newId) {
      this.academicLife = newId;
    },
  },

  methods: {
    ...mapActions("occurrences", ["ActionPutOccurrences"]),

    async updateAsyncOccurrence() {
      // Calcular a visibilidade da ocorrência
      this.message = "";
      let visibility =
        1 +
        (this.secretariaCheck == true ? 2 : 0) +
        (this.professorCheck == true ? 4 : 0) +
        (this.alunoCheck == true ? 8 : 0);

      if (this.text == "") {
        this.message = "O campo descrição não deve ser vazio!";
      } else {
        if (this.text.length > 2045) {
          this.message =
            "O campo descrição excedeu o limite de 2045 caracteres!";
        } else {
          try {
            this.disabled = true;
            this.occurrence.visibility = visibility;
            this.occurrence.text = this.text; 
            this.occurrence.createdAt = new Date();
            this.occurrence.user = {};

            const payload = {
              body: this.occurrence,
              academicLifeId: this.academicLife,
            };

            await this.ActionPutOccurrences(payload);
            this.closeUpdateDialog();
          } catch (err) {
            console.error(err);
          } finally {
            this.disabled = false;
          }
        }
      }
    },

    updateOccurrence() {
      this.updateAsyncOccurrence();
    },

    closeUpdateDialog() {
      this.$emit("close-up-dialog");
    },

    updateCheckBox(visibility){
      if(visibility){
        let arrayUsers = occurrenceUtils.arrayVisibility(visibility);
        this.secretariaCheck = arrayUsers.find(e => e === users.SEC) === users.SEC;
        this.professorCheck = arrayUsers.find(e => e === users.PROF) === users.PROF;
        this.alunoCheck = arrayUsers.find(e => e === users.STUDENT) === users.STUDENT;
      }
    }
  },
};
</script>