<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Curso</h3>
           <hr>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong>Curso:</strong>
          {{ course }}
        </v-col>
        <v-col>
          <strong>Situação:</strong>
          {{ situation }}
        </v-col>
        <v-col>
          <strong>Início:</strong>
          {{ entryDate }}
        </v-col>       
      </v-row>
      <v-row>
           <v-col>
          <strong>Período:</strong>
          {{ currentPeriod }} <label v-if="courseBreaks > 0" class="overline text-monospace"> [{{courseBreaks}}]</label>
        </v-col>
        <v-col>
          <strong>Créditos:</strong>
          {{ credits }}
        </v-col>
        <v-col>
          <strong>Limite:</strong>
          {{ deadlineDate }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  data() {
    return {
      academicLife: {},
      entryDate: "",
      deadlineDate: "",
      currentPeriod: 0,
      courseBreaks: 0,
      credits: 0,
      course: "",
      totalCredits: 0,
      situation: ""
    };
  },

  watch: {
    getStudent(newValue) {

      const academicLife = newValue.academicLifes.filter(
        acal => acal.situation === "Ativo - Curso em Andamento" && acal.course.code != '99'
      )[0];

      this.academicLife = academicLife;
      this.entryDate = formatDate.dmy(academicLife.entryDate);
      this.credits = academicLife.creditsTaken;
      this.totalCredits = academicLife.course.totalCreditRequired;
      this.course = academicLife.course.name;
      this.deadlineDate = formatDate.dmy(academicLife.deadlineDate);
      this.currentPeriod = academicLife.currentPeriod == 0? "Ainda não iniciado": academicLife.currentPeriod;
      this.courseBreaks = academicLife.courseBreaks.length;
      this.situation = academicLife.situation;
    }
  },

  computed: {
    ...mapGetters("student", ["getStudent"])
  }
};
</script>