var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "start" } },
        [
          _c("v-col", { staticClass: "mb-0 pb-0", attrs: { cols: "6" } }, [
            _c("h3", [_vm._v("Ocorrências")])
          ]),
          _c(
            "v-col",
            { staticClass: "text-end mb-0 pb-0", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "green", "x-small": "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = !_vm.dialog
                    }
                  }
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              )
            ],
            1
          ),
          _c("v-col", { staticClass: "mt-0 pt-0", attrs: { cols: "12" } }, [
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("occurrences-table", {
            attrs: {
              items: _vm.items,
              loading: _vm.loading,
              academicLifeId: _vm.academicLifeId
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("occurrence-dialog", {
            attrs: { academicLifeId: _vm.academicLifeId },
            on: { "close-dialog": _vm.closeDialog }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }