var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.occurrences,
                  dense: "",
                  "hide-default-footer": "",
                  "no-data-text": "Não há ocorrências para a vida acadêmica",
                  loading: _vm.loading,
                  "loading-text": "Pesquisando ocorrências..."
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(props) {
                      return [
                        _c("tr", [
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.createdAt))
                          ]),
                          _vm.isAdmin
                            ? _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.origin))
                              ])
                            : _vm._e(),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.type))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _c("div", { staticClass: "altura" }, [
                              _vm._v(_vm._s(props.item.text))
                            ])
                          ]),
                          _vm.isAdmin
                            ? _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.users))
                              ])
                            : _vm._e(),
                          _vm.isAdmin
                            ? _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.user))
                              ])
                            : _vm._e(),
                          _vm.isAdmin
                            ? _c(
                                "td",
                                { staticClass: "text-xs-left" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12",
                                            lg: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled:
                                                  props.item.removed == true,
                                                outlined: "",
                                                color: "green lighten-2",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showUpdateDialog(
                                                    props.item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("create")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12",
                                            lg: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "red lighten-2",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showDelDialog(
                                                    props.item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("delete_outline")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialogDel,
            callback: function($$v) {
              _vm.dialogDel = $$v
            },
            expression: "dialogDel"
          }
        },
        [
          _c("OccurrenceDialogDel", {
            attrs: { item: _vm.occurrence, academicLifeId: _vm.academicLife },
            on: { "close-del-dialog": _vm.closeDelDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialogUpdate,
            callback: function($$v) {
              _vm.dialogUpdate = $$v
            },
            expression: "dialogUpdate"
          }
        },
        [
          _c("OccurrenceDialogUpdate", {
            attrs: { item: _vm.occurrence, academicLifeId: _vm.academicLife },
            on: { "close-up-dialog": _vm.closeUpdateDialog }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }