var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "p",
        [
          _vm._v(" Lattes: " + _vm._s(_vm.student.lattes) + " "),
          _vm.isStudent
            ? _c(
                "v-btn",
                {
                  attrs: { color: "green", outlined: "", "x-small": "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = !_vm.dialog
                    }
                  }
                },
                [
                  _vm.student.lattes === ""
                    ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")])
                    : _c("v-icon", { attrs: { small: "" } }, [_vm._v("create")])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("label", [_vm._v(_vm._s(_vm.title))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              counter: "254",
                              clearable: "",
                              rules: _vm.rule,
                              disabled: _vm.disabled
                            },
                            on: {
                              keypress: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.putAsyncLattes($event)
                              }
                            },
                            model: {
                              value: _vm.newLattes,
                              callback: function($$v) {
                                _vm.newLattes = $$v
                              },
                              expression: "newLattes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.disabled,
                                  expression: "disabled"
                                }
                              ],
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "green" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.error,
                                  expression: "error"
                                }
                              ],
                              staticClass: "red--text text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("label", { staticClass: "text--red" }, [
                                _vm._v(_vm._s(_vm.message))
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12", sm: "12", md: "6" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    color: "green",
                                    block: "",
                                    disabled: _vm.disabled
                                  },
                                  on: { click: _vm.putAsyncLattes }
                                },
                                [_vm._v("Adicionar")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12", sm: "12", md: "6" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    color: "red",
                                    block: "",
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = false
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }