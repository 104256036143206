<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row justify="center">
        <h5 class="title">Remover a Ocorrência?</h5>
        </v-row>
      </v-card-title>
      <v-card-text>
        
          <p class="mt-1 ml-5 mr-2">
            <strong>Tipo:</strong>
            {{ occurrence.type }}
          </p>
          <p class="mt-1 ml-5 mr-2">
            <strong>Descrição:</strong>
            {{ occurrence.text }}
          </p>
       
      </v-card-text>
      <v-card-actions>
        <v-container>
        <v-row justify="center">
          <v-col cols="12" class="text-center" v-show="disabled">
            <v-progress-circular indeterminate color="red"></v-progress-circular>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn color="green" block outlined @click="removeOccurrence()">OK</v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn color="red" block outlined @click="closeDelDialog">Cancelar</v-btn>
          </v-col>
        </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item", "academicLifeId"],

  data() {
    return {
      occurrence: {},
      academicLife: null,
      disabled: false
    };
  },

  created() {
    this.occurrence = this.item;
    this.academicLife = this.academicLifeId;
  },

  watch: {
    item(newItem) {
      this.occurrence = newItem;
    },
    academicLifeId(newId) {
      this.academicLife = newId;
    },
  },

  methods: {
    ...mapActions("occurrences", ["ActionDelOccurrence"]),

    async delAsyncOccurrence() {
      this.disabled = true;
      try {
        const payload = {
          id: this.occurrence.id,
          academicLifeId: this.academicLife,
        };
        await this.ActionDelOccurrence(payload);
        this.closeDelDialog();
      } catch (err) {
        console.error(err);
      } finally {
        this.disabled = false;
      }
    },

    removeOccurrence() {
      this.delAsyncOccurrence();
    },

    closeDelDialog() {
      this.$emit("close-del-dialog");
    },
  },
};
</script>