var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _c("h5", { staticClass: "title" }, [
                  _vm._v("Remover a Ocorrência?")
                ])
              ])
            ],
            1
          ),
          _c("v-card-text", [
            _c("p", { staticClass: "mt-1 ml-5 mr-2" }, [
              _c("strong", [_vm._v("Tipo:")]),
              _vm._v(" " + _vm._s(_vm.occurrence.type) + " ")
            ]),
            _c("p", { staticClass: "mt-1 ml-5 mr-2" }, [
              _c("strong", [_vm._v("Descrição:")]),
              _vm._v(" " + _vm._s(_vm.occurrence.text) + " ")
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.disabled,
                              expression: "disabled"
                            }
                          ],
                          staticClass: "text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "red" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green",
                                block: "",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeOccurrence()
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red", block: "", outlined: "" },
                              on: { click: _vm.closeDelDialog }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }