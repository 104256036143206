import { render, staticRenderFns } from "./Ped.vue?vue&type=template&id=539b0e8a&scoped=true&"
import script from "./Ped.vue?vue&type=script&lang=js&"
export * from "./Ped.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539b0e8a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VDataTable,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('539b0e8a')) {
      api.createRecord('539b0e8a', component.options)
    } else {
      api.reload('539b0e8a', component.options)
    }
    module.hot.accept("./Ped.vue?vue&type=template&id=539b0e8a&scoped=true&", function () {
      api.rerender('539b0e8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/student/ped/Ped.vue"
export default component.exports