var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-row", [_c("v-col", [_c("h3", [_vm._v("PED")]), _c("hr")])], 1),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  search: _vm.search,
                  "hide-default-footer": "",
                  "no-data-text": "Não há dados disponíveis para a consulta"
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.code))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.score))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(
                            _vm._s(
                              props.item.semester
                                .split("/")
                                .reverse()
                                .join("")
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }