var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [_c("p", [_vm._v("Buscando dados do aluno...")])])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { wrap: "", fluid: "" } },
        [
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isStudent,
                  expression: "!isStudent"
                }
              ],
              attrs: { cols: "12" }
            },
            [_c("h2", {}, [_vm._v(_vm._s(_vm.student.name))]), _c("hr")]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("p", [_vm._v("R.A: " + _vm._s(_vm.student.academicCode))]),
              _c("p", [_vm._v("E-mail: " + _vm._s(_vm.student.email))]),
              _c("p", [_vm._v("E-mail: " + _vm._s(_vm.student.ecademicEmail))]),
              _c("Lattes", { attrs: { student: _vm.student } }),
              _c("p", [_vm._v(" CR: "), _c("strong", [_vm._v(_vm._s(_vm.cr))])])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }