<template>
  <div>
    <div v-if="loading"><p>Buscando dados do aluno...</p></div>
    <v-row wrap fluid>
      <v-col cols="12" v-show="!isStudent">
        <h2 class>{{ student.name }}</h2>
        <hr />
      </v-col>
  
      <v-col cols="12">
        <p>R.A: {{ student.academicCode }}</p>
        <p>E-mail: {{ student.email }}</p>
        <p>E-mail: {{ student.ecademicEmail }}</p>
        <Lattes :student="student"></Lattes>
        <p>
          CR: <strong>{{ cr }}</strong>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { studentUtils } from "@/utils/studentUtils";
import * as storage from "@/pages/auth/storage";

import Lattes from "@/components/student/lattes/Lattes";

export default {
  name: "StudentInfo",
  props: ["academicCode"],
  components: { Lattes },
  data() {
    return {
      loading: true,
      cr: 0,
    };
  },

  created() {
    this.getStudent();
    this.user = storage.getLocalData().user;
  },

  computed: {
    ...mapState("student", ["student"]),

    isStudent() {
      return this.user.roles.includes("student");
    },
  },

  methods: {
    ...mapActions("student", ["ActionGetStudentAcademicCode"]),

    async getStudent() {
      try {
        await this.ActionGetStudentAcademicCode(this.academicCode);
      } catch (err) {
        alert("Não foi possível buscar o aluno");
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    student(newStudent) {
      let vcr = studentUtils.returnActiveAcademicLife(
        newStudent.academicLifes
      ).vcr;
      this.cr = vcr;
    },
  },
};
</script>