<template>
  <div>
    <p>
      Lattes: {{ student.lattes }}
      <v-btn v-if="isStudent" color="green" outlined x-small @click="dialog = !dialog">
        <v-icon v-if="student.lattes === ''" small>add</v-icon>
        <v-icon v-else small>create</v-icon>
      </v-btn>
    </p>

    <v-dialog v-model="dialog" max-width="700">
      <v-container fluid>
        <v-card outlined>
          <v-card-title>
            <v-row justify="center">
              <label>{{ title }}</label>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-text-field
                outlined
                dense
                counter="254"
                clearable
                v-model="newLattes"
                :rules="rule"
                :disabled="disabled"
                @keypress.enter="putAsyncLattes"
              ></v-text-field>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="12" class="text-center" v-show="disabled">
                <v-progress-circular indeterminate color="green"></v-progress-circular>
              </v-col>
              <v-col cols="12" class="red--text text-center" v-show="error">
                <label class="text--red">{{ message }}</label>  
              </v-col>
              <v-col cols="12" sm="12" md="6" class="text-center">
                <v-btn
                  outlined
                  color="green"
                  block
                  @click="putAsyncLattes"
                  :disabled="disabled"
                >Adicionar</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="text-center">
                <v-btn
                  outlined
                  color="red"
                  block
                  @click="dialog = false"
                  :disabled="disabled"
                >Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { authUtils } from "@/utils/authUtils";

export default {
  name: "Lattes",
  props: ["student"],
  data() {
    return {
      newLattes: "",
      dialog: false,
      disabled: false,
      isStudent: true,
      error: false,
      rule: [
        () => !!this.newLattes || "O campo não pode ser vazio!",
        () =>
          (!!this.newLattes && this.newLattes.length <= 254) ||
          "O link não deve ultrapassar 254 caracteres",
      ],
      title: "",
      message: "Ocorreu um erro, por favor tente novamente mais tarde!",
    };
  },

  created() {
    if (this.student.lattes === "") this.title = "Incluir Lattes";
    else this.title = "Alterar Lattes";
    this.isStudent = authUtils.isStudent();
  },

  methods: {
    ...mapActions("lattes", ["ActionPutStudentLattes"]),

    async putAsyncLattes() {
      if (
        this.newLattes != null &&
        this.newLattes.length > 0 &&
        this.newLattes.length < 254
      ) {
          this.disabled = true;
           this.error = false; 
        try {
          const res = await this.ActionPutStudentLattes({
            academic_code: this.student.academicCode,
            body: { link: this.newLattes },
          });
          if (res.status === 200) this.student.lattes = res.bodyText;
          this.disabled = false;
          this.dialog = false;
        } catch (err) {
            this.error = true;
          console.error(err);
        }
      }
    },
  },

  watch: {
    student(newStudent) {
      if (newStudent.lattes === "") this.title = "Incluir Lattes";
      else this.title = "Alterar Lattes";
    },
  },
};
</script>