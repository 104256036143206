<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Disciplinas</h3>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="subjects"
            v-bind:search="search"
            item-key="code"
            hide-default-footer
            no-data-text="Não há dados disponíveis para a consulta"
            v-bind:loading="showLoading"
          >
            <template v-slot:items="props">
              <td class="text-xs-left">{{ props.item.code }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.start_date.split('/').reverse().join('') }}</td>
              <td class="text-xs-left">{{ props.item.score }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "Subjects",

  data() {
    return {
      show: false,
      showLoading: true,
      subjects: [],
      search: "",
      headers: [
        { sortable: true, text: "Código", value: "code", align: "left" },
        { sortable: true, text: "Disciplina", value: "name", align: "left" },
        {
          sortable: true,
          text: "Cursada em",
          value: "start_date",
          align: "left"
        },
        { sortable: true, text: "Conceito", value: "score", align: "left" },
        { sortable: true, text: "Instituto", value: "institute", align: "left" }
      ],
    };
  },
  methods: {
    ...mapActions("subjects", ["ActionGetSubjects", "ActionGetCurrentSubjects"]),

    async getAsyncSubjects(academicLifeId) {
      try {
        await this.ActionGetSubjects(academicLifeId);
      } catch (err) {
        console.error("Erro ao buscar as disciplinas", err);
      }
    },

    async getAsyncCurrentSubjects(academicLifeId) {
      try{
        await this.ActionGetCurrentSubjects(academicLifeId);
      } catch (err) {
        console.error('Erro ao buscar as disciplinas atuais', err);
      }
    }
  },
  watch: {
    getStudent(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
        acal => acal.situation === "Ativo - Curso em Andamento"
      );
      this.getAsyncSubjects(academicLife[0].id);
      this.getAsyncCurrentSubjects(academicLife[0].id);
    },

    getSubjects(newValue) {
      this.subjects = [];
      newValue.map(sub => {
        this.subjects.push({
          code: sub.subjectHistory.subject.code,
          class: sub.subjectHistory.subject.category,
          name: sub.subjectHistory.subject.name,
          score: sub.score,
          start_date: formatDate.ys(sub.date),
          institute: sub.subjectHistory.subject.institute
        });
      });

      this.showLoading = false;
    },

    getCurrentSubjects(newValue){
      newValue.map(el =>{
          let res = this.subjects.find(element => element.code == el.subject.code);
          if(res == undefined){
            this.subjects.push({
              code: el.subject.code,
              name: el.subject.name,
              class: el.classCode,
              score: '',
              start_date: 'Cursando',
              institute: el.subject.institute
            });
          }
      });

    }
  },
  computed: {
    ...mapGetters("student", ["getStudent"]),
    ...mapGetters("subjects", ["getSubjects", "getCurrentSubjects"])
  }
};
</script>

