/**
 *      Utilitário para ocorrências
 */


export const users = {
    ADMIN : "Administração",
    SEC : "Secretaria",
    PROF : "Professor",
    STUDENT : "Aluno",
}

export const occurrenceUtils = { 

    arrayVisibility: (number) => { 
    
        switch (number){
            case 1:
                return [users.ADMIN];
            case 3:
                return [users.ADMIN, users.SEC];
            case 5:
                return [users.ADMIN, users.PROF];
            case 7:
                return [users.ADMIN, users.SEC, users.PROF];
            case 9: 
                return [users.ADMIN, users.STUDENT];
            case 11:
                return [users.ADMIN, users.SEC, users.STUDENT];  
            case 13:
                return [users.ADMIN, users.PROF, users.STUDENT];  
            case 15:
                return [users.ADMIN, users.SEC, users.PROF, users.STUDENT];
            default:
                return []    
                 
        }
    }
}