<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>Trancamentos</h3>
        <hr />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          v-bind:headers="headers"
          v-bind:items="trancamentos"
          item-key="id"
          hide-default-footer
          no-data-text="Não há trancamentos disponíveis para a consulta"
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.startDate }}</td>
            <td class="text-xs-left">{{ props.item.endDate }}</td>
            <td class="text-xs-left">{{ props.item.status }}</td>
            <td class="text-xs-left">{{ props.item.description }}</td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "course_break",
  props: [],
  data() {
    return {
      trancamentos: [],
      id: 0,
      headers: [
        { sortable: false, text: "Início", value: "startDate", align: "left" },
        { sortable: false, text: "Término", value: "endDate", align: "left" },
        { sortable: false, text: "Situação", value: "status", align: "left" },
        {
          sortable: false,
          text: "Motivo",
          value: "description",
          align: "left",
        },
      ],
    };
  },

  computed: {
    ...mapState("student", ["student"]),
    ...mapState("courseBreak", ["courseBreak"]),
  },

  methods: {
    ...mapActions("courseBreak", ["ActionGetCourseBreak"]),

    async getAsyncCourseBreak(academicLifeId) {
      try {
        this.ActionGetCourseBreak(academicLifeId);
      } catch (err) {
        console.log(err);
      }
    },
  },

  watch: {
    student(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
        (acal) => acal.situation === "Ativo - Curso em Andamento"
      );

      this.getAsyncCourseBreak(academicLife[0].id);
    },

    courseBreak(newValue) {
      this.trancamentos = [];
      newValue.map((t) => {
        this.trancamentos.push({
          id: t.id,
          startDate: formatDate.dmy(t.startDate),
          endDate: formatDate.dmy(t.endDate),
          status: t.status,
          description: t.description,
        });
      });
    },
  },
};
</script>