<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Orientador</h3>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headersO"
            v-bind:items="orientadores"
            v-bind:search="search"
            hide-default-footer
            no-data-text="Não há dados disponíveis para a consulta"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.id }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.status }}</td>
              <td
                class="text-xs-left"
              >{{ props.item.guidanceStartDate }} - {{ props.item.guidanceEndDate }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div v-if="coorientadores.length >0">
        <v-row>
          <v-col>
            <h3>Coorientador</h3>
            <hr />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              v-bind:headers="headersC"
              v-bind:items="coorientadores"
              v-bind:search="search"
              hide-default-footer
              no-data-text="Não há dados disponíveis para a consulta"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.id }}</td>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.status }}</td>
                <td
                  class="text-xs-left"
                >{{ props.item.dataInicioCoorientacao }} - {{ props.item.dataFimCoorientacao }}</td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "guidance",
  data() {
    return {
      show: false,
      search: "",
      headersO: [
        {
          sortable: false,
          text: "Matrícula",
          value: "id",
          align: "left"
        },
        {
          sortable: false,
          text: "Nome do docente",
          value: "name",
          align: "left"
        },
        {
          sortable: false,
          text: "Situação",
          value: "status",
          align: "left"
        },
        {
          sortable: false,
          text: "Início",
          value: "guidanceStartDate",
          align: "left"
        },
        {
          sortable: false,
          text: "Término",
          value: "guidanceEndDate",
          align: "left"
        }
      ],
      headersC: [
        {
          sortable: false,
          text: "Matrícula",
          value: "id",
          align: "left"
        },
        {
          sortable: false,
          text: "Nome do docente",
          value: "name",
          align: "left"
        },
        {
          sortable: false,
          text: "Situação",
          value: "status",
          align: "left"
        },
        {
          sortable: false,
          text: "Início",
          value: "dataInicioCoorientacao",
          align: "left"
        },
        {
          sortable: false,
          text: "Término",
          value: "dataFimCoorientacao",
          align: "left"
        }
      ],
      orientadores: [],
      coorientadores: []
    };
  },
  watch: {
    getStudent(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
        acal => acal.situation === "Ativo - Curso em Andamento"
      );

      this.getAsyncGuidance(academicLife[0].id);
    },

    getGuidance(newValue) {
      if (newValue != null) {
        this.orientadores = [];
        this.coorientadores = [];

        newValue.map(guidance => {
          this.orientadores.push({
            id: guidance.orientador.professor.academicCode,
            name: guidance.orientador.professor.name,
            status: guidance.status,
            guidanceStartDate: formatDate.dmy(guidance.guidanceStartDate),
            guidanceEndDate:
              guidance.guidanceEndDate === null
                ? ""
                : formatDate.dmy(guidance.guidanceEndDate)
          });

          if (guidance.coorientador.professor != null) {
            const coorientador = this.coorientadores.filter(
              c =>
                c.academicCode !== guidance.coorientador.professor.academicCode
            );

            if (coorientador.length == 0) {
              this.coorientadores.push({
                id: guidance.coorientador.professor.academicCode,
                name: guidance.coorientador.professor.name,
                status: guidance.status,
                dataInicioCoorientacao: formatDate.dmy(
                  guidance.dataInicioCoorientacao
                ),
                dataFimCoorientacao:
                  guidance.dataFimCoorientacao === null
                    ? ""
                    : formatDate.dmy(guidance.dataFimCoorientacao)
              });
            }
          }
        });
      }
    }
  },

  computed: {
    ...mapGetters("student", ["getStudent"]),
    ...mapGetters("guidance", ["getGuidance"])
  },

  methods: {
    ...mapActions("guidance", ["ActionGetGuidance"]),

    async getAsyncGuidance(academicLifeId) {
      try {
        await this.ActionGetGuidance(academicLifeId);
      } catch (err) {
        console.log("Não foi possível buscar as orientações do aluno");
      }
    }
  }
};
</script>
