var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [_c("v-col", [_c("h3", [_vm._v("Orientador")]), _c("hr")])],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headersO,
                      items: _vm.orientadores,
                      search: _vm.search,
                      "hide-default-footer": "",
                      "no-data-text": "Não há dados disponíveis para a consulta"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(props.item.id))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(props.item.name))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(props.item.status))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(
                                _vm._s(props.item.guidanceStartDate) +
                                  " - " +
                                  _vm._s(props.item.guidanceEndDate)
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.coorientadores.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h3", [_vm._v("Coorientador")]),
                        _c("hr")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headersC,
                              items: _vm.coorientadores,
                              search: _vm.search,
                              "hide-default-footer": "",
                              "no-data-text":
                                "Não há dados disponíveis para a consulta"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v(_vm._s(props.item.id))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v(_vm._s(props.item.name))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v(_vm._s(props.item.status))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.item.dataInicioCoorientacao
                                            ) +
                                              " - " +
                                              _vm._s(
                                                props.item.dataFimCoorientacao
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1715964177
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }