<template>
  <v-container fluid>
    <v-row align="start">
      <v-col cols="6" class="mb-0 pb-0">
        <h3>Ocorrências</h3>
      </v-col>
      <v-col cols="6" class="text-end mb-0 pb-0">
        <v-btn outlined color="green" x-small @click="dialog = !dialog">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="mt-0 pt-0">
        <hr />
      </v-col>
    </v-row>

    <v-row>
      <occurrences-table
        :items="items"
        :loading="loading"
        :academicLifeId="academicLifeId"
      ></occurrences-table>
    </v-row>

    <v-dialog v-model="dialog" max-width="600">
      <occurrence-dialog
        :academicLifeId="academicLifeId"
        v-on:close-dialog="closeDialog"
      ></occurrence-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { formatDate } from "@/utils/formatDate";
import { occurrenceUtils } from "@/utils/occurrenceUtils";
import { studentUtils } from "@/utils/studentUtils";

import OccurrencesTable from "./OccurrencesTable";
import OccurrenceDialog from "./OccurrenceDialog";

export default {
  name: "occurrences",
  props: ["academicLifeId"],
  components: { OccurrencesTable, OccurrenceDialog },
  data() {
    return {
      disabled: false,
      dialog: false,
      items: [],
      loading: false,
      academicLife: null,
    };
  },

  created() {
    this.academicLife = this.academicLifeId;
  },

  computed: {
    ...mapState("occurrences", ["occurrences"]),
    ...mapState("student", ["student"]),
  },

  methods: {
    ...mapActions("occurrences", ["ActionGetOccurrences"]),

    async getAsyncOccurrence(id) {
      this.loading = true;
      try {
        await this.ActionGetOccurrences(id);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    closeDialog: function () {
      this.dialog = false;
    },

    returnUsers(visibility) {
      let users = occurrenceUtils.arrayVisibility(visibility);
      return users.join(" - ");
    },
  },

  watch: {
    academicLifeId(newValue) {
      if (newValue != "") {
        this.academicLife = newValue;
        this.getAsyncOccurrence(newValue);
      }
    },

    student(newStudent) {
      let id = studentUtils.returnActiveAcademicLife(
        newStudent.academicLifes
      ).id;
      this.getAsyncOccurrence(id);
    },

    occurrences(newValue) {
      this.items = [];
      if (newValue) {
        newValue.map((c) => {
          this.items.push({
            id: c.id,
            createdAt: formatDate.dmy(c.createdAt),
            type: c.occurrenceTypeTO.name,
            origin: c.occurrenceTypeTO.origin,
            text: c.text,
            user: c.user.name,
            visibility: c.visibility,
            users: this.returnUsers(c.visibility),
          });
        });
      }
    },
  },
};
</script>