<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>PED</h3>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="items"
            v-bind:search="search"
            hide-default-footer
            no-data-text="Não há dados disponíveis para a consulta"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.code }}</td>
              <td class="text-xs-left">{{ props.item.score }}</td>
              <td class="text-xs-left">{{ props.item.semester.split('/').reverse().join('') }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "ped",

  data() {
    return {
      search: "",
      pagination: {
        sortBy: "semester"
      },
      headers: [
        {
          sortable: false,
          text: "Código",
          value: "code",
          align: "left"
        },
        {
          sortable: false,
          text: "Disciplina",
          value: "subjectName",
          align: "left"
        },
        {
          sortable: false,
          text: "Turma",
          value: "classCode",
          align: "left"
        },
        {
          sortable: false,
          text: "Tipo",
          value: "score",
          align: "left"
        },
        {
          sortable: false,
          text: "Cursado em",
          value: "semester",
          align: "left"
        }
      ],
      items: []
    };
  },

  created() {

  },

  computed: {
    ...mapGetters("student", ["getStudent"]),
    ...mapGetters("ped", ["getPed"])
  },

  watch: {
    getStudent(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
        acal => acal.situation === "Ativo - Curso em Andamento"
      );

      this.getAsyncPed(academicLife[0].id);
    },
    getPed(newValue) {
      this.items = [];
      newValue.map(p => {
        this.items.push({
          code: p.subject.code,
          score: p.score,
          classCode: p.classCode,
          subjectName: p.subjectName,
          semester: formatDate.ys(p.pedDate)
        });
      });
    }
  },
  methods: {
    ...mapActions("ped", ["ActionGetPed"]),

    async getAsyncPed(academicLifeId) {
      try {
        await this.ActionGetPed(academicLifeId);
      } catch (err) {
        console.log("Não foi possível buscar os peds");
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

