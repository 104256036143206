<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Nova Ocorrência</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-select
              v-if="isAdmin"
              label="Tipos de Usuários"
              dense
              outlined
              sele
              :items="roles"
              v-model="role"
              no-data-text="Não há tipos de usuários"
              persistent-hint
              return-object
              :disabled="disabled"
            ></v-select>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-select
              label="Tipo da ocorrência"
              dense
              v-model="userType"
              :items="userTypes"
              :hint="userType.origin"
              item-text="name"
              item-value="id"
              :autofocus="true"
              outlined
              no-data-text="Não há tipos"
              persistent-hint
              return-object
              :disabled="disabled"
            ></v-select>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  :disabled="disabled"
                  color="green"
                  v-model="secretariaCheck"
                  label="Secretaria"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  :disabled="disabled"
                  color="green"
                  v-model="professorCheck"
                  label="Professor"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox :disabled="disabled" color="green" v-model="alunoCheck" label="Aluno"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-textarea
              outlined
              :disabled="disabled"
              :rows="6"
              counter="2045"
              label="Descrição da ocorrência"
              v-model="text"
              :rules="[rules.length]"
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate color="green" v-show="disabled == true"></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
          <v-col cols="12" v-show="message.length > 0" class="text-center">
            <label class="label red--text">{{ message }}</label>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="green"
              outlined
              block
              :disabled="disabled"
              @click="postAsyncOccurrence"
            >Adicionar</v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn color="red" outlined block @click="closeDialog">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { authUtils } from "@/utils/authUtils";

export default {
  name: "occurrence_dialog",
  props: ["academicLifeId"],
  data() {
    return {
      disabled: false,
      academicLife: null,
      types: [],
      userTypes: [],
      userType: {},
      roles: ["Aluno", "Professor", "Secretaria", "Administração"],
      role: "",
      text: "",
      isAdmin: false,
      secretariaCheck: false, // 2
      professorCheck: false, // 4
      alunoCheck: false, // 8
      message: "",
      rules: {
        length: (v) =>
          (v || "").length <= 2045 ||
          "O texto deve ser conter menos que 2045 caracteres!",
      },
    };
  },

  created() {
    this.getAsyncOccurrenceType();
    this.isAdmin = authUtils.isAdmin();
    this.academicLife = this.academicLifeId;
  },

  computed: {
    ...mapState("occurrenceType", ["occurrenceType", "occurrenceTypes"]),
  },

  methods: {
    ...mapActions("occurrenceType", ["ActionGetOccurrenceTypeActives"]),
    ...mapActions("occurrences", ["ActionPostOccurrences"]),

    async getAsyncOccurrenceType() {
      this.disabled = true;
      try {
        await this.ActionGetOccurrenceTypeActives();
      } catch (err) {
        console.error(err);
      } finally {
        this.disabled = false;
      }
    },

    async postAsyncOccurrence() {
      this.disabled = true;
      this.message = "";
      try {
        // Calcular a visibilidade da ocorrência
        let visibility =
          1 +
          (this.secretariaCheck == true ? 2 : 0) +
          (this.professorCheck == true ? 4 : 0) +
          (this.alunoCheck == true ? 8 : 0);

        if (!this.userType.id || !this.academicLife || !this.text != "")
          this.message = "Preencher todos os campos!";
        else {
          if (this.text.length > 2045) {
            this.message = "O texto excedeu o limite de caracteres!";
          } else {
            const body = {
              visibility: visibility,
              occurrenceType: this.userType.id,
              academicLife: this.academicLife,
              text: this.text,
            };

            await this.ActionPostOccurrences(body);
            this.closeDialog();
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.disabled = false;
      }
    },

    closeDialog() {
      this.alunoCheck = false;
      this.secretariaCheck = false;
      this.professorCheck = false;
      this.text = "";
      this.userType = {};
      this.role = {};
      this.disabled = false;
      this.message = '';
      this.$emit("close-dialog");
    },
  },

  watch: {
    occurrenceType(newValue) {
      newValue.types.map((o) => {
        o.map((sub) => {
          if (this.isAdmin) {
            this.types.push({
              id: sub.id,
              name: sub.name,
              origin: sub.origin,
            });
          } else {
            this.userTypes.push({
              id: sub.id,
              name: sub.name,
              origin: sub.origin,
            });
          }
        });
      });
    },

    role(newValue) {
      this.disabled = true;
      this.userTypes = [];
      this.userType = {};
      this.userTypes = this.types.filter((t) => t.origin == newValue); // Filtra as opções com base na seleção do 1° select
      this.disabled = false;
    },

    academicLifeId(newValue) {
      this.academicLife = newValue;
    },
  },
};
</script>