var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [_c("v-col", [_c("h3", [_vm._v("Curso")]), _c("hr")])],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("strong", [_vm._v("Curso:")]),
                _vm._v(" " + _vm._s(_vm.course) + " ")
              ]),
              _c("v-col", [
                _c("strong", [_vm._v("Situação:")]),
                _vm._v(" " + _vm._s(_vm.situation) + " ")
              ]),
              _c("v-col", [
                _c("strong", [_vm._v("Início:")]),
                _vm._v(" " + _vm._s(_vm.entryDate) + " ")
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("strong", [_vm._v("Período:")]),
                _vm._v(" " + _vm._s(_vm.currentPeriod) + " "),
                _vm.courseBreaks > 0
                  ? _c("label", { staticClass: "overline text-monospace" }, [
                      _vm._v(" [" + _vm._s(_vm.courseBreaks) + "]")
                    ])
                  : _vm._e()
              ]),
              _c("v-col", [
                _c("strong", [_vm._v("Créditos:")]),
                _vm._v(" " + _vm._s(_vm.credits) + " ")
              ]),
              _c("v-col", [
                _c("strong", [_vm._v("Limite:")]),
                _vm._v(" " + _vm._s(_vm.deadlineDate) + " ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }