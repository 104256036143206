<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="occurrences"
          dense
          hide-default-footer
          no-data-text="Não há ocorrências para a vida acadêmica"
          :loading="loading"
          loading-text="Pesquisando ocorrências..."
        >
          <template v-slot:item="props">
            <tr>
              <td class="text-xs-left">{{ props.item.createdAt }}</td>
              <td class="text-xs-left" v-if="isAdmin">{{ props.item.origin }}</td>
              <td class="text-xs-left">{{ props.item.type }}</td>
              <td class="text-xs-left">
                <div class="altura">{{ props.item.text }}</div>
              </td>
              <td class="text-xs-left" v-if="isAdmin">{{ props.item.users }}</td>
              <td class="text-xs-left" v-if="isAdmin">{{ props.item.user }}</td>

              <td class="text-xs-left" v-if="isAdmin">
                <v-row justify="center">
                  <v-col class="text-center" cols="12" sm="12" md="12" lg="6">
                    <v-btn
                      :disabled="props.item.removed == true"
                      outlined
                      color="green lighten-2"
                      x-small
                      @click="showUpdateDialog(props.item)"
                    >
                      <v-icon small>create</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-center" cols="12" sm="12" md="12" lg="6">
                    <v-btn
                      outlined
                      color="red lighten-2"
                      x-small
                      @click="showDelDialog(props.item)"
                    >
                      <v-icon small>delete_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDel" max-width="600">
      <OccurrenceDialogDel
        :item="occurrence"
        :academicLifeId="academicLife"
        v-on:close-del-dialog="closeDelDialog"
      ></OccurrenceDialogDel>
    </v-dialog>

    <v-dialog v-model="dialogUpdate" max-width="600">
      <OccurrenceDialogUpdate
        :item="occurrence"
        :academicLifeId="academicLife"
        v-on:close-up-dialog="closeUpdateDialog"
      ></OccurrenceDialogUpdate>
    </v-dialog>
  </v-container>
</template>

<script>
import { authUtils } from "@/utils/authUtils";

import OccurrenceDialogDel from "./OccurrenceDialogDel";
import OccurrenceDialogUpdate from "./OccurrenceDialogUpdate";

export default {
  name: "occurrences-table",
  props: ["items", "loading", "academicLifeId"],
  components: { OccurrenceDialogDel, OccurrenceDialogUpdate },
  data() {
    return {
      headers: [
        {
          sortable: true,
          text: "Data",
          value: "createdAt",
          align: "left",
        },
        {
          sortable: true,
          text: "Origem",
          value: "origin",
          align: "left",
        },
        {
          sortable: true,
          text: "Tipo",
          value: "type",
          align: "left",
        },
        {
          sortable: true,
          text: "Descrição",
          value: "text",
          align: "left",
        },
        {
          sortable: false,
          text: "Visibilidade",
          value: "users",
          align: "left",
        },
        {
          sortable: true,
          text: "Autor",
          value: "user",
          align: "left",
        },
        {
          sortable: false,
          text: "Editar",
          value: "edit",
          align: "center",
          width: "10%",
        },
      ],
      occurrences: [],
      occurrence: {},
      academicLife: null,
      dialogDel: false,
      dialogUpdate: false,
      isAdmin: false,
    };
  },

  created() {
    this.isAdmin = authUtils.isAdmin();
    this.academicLife = this.academicLifeId;
    if (this.items) {
      this.occurrences = this.items;
    }

    if (this.isAdmin == false) {
      this.headers.pop({ value: "edit" });
      this.headers.pop({ value: "users" });
      this.headers.pop({ value: "user" });
      this.headers.pop({ value: "origin" });
    }
  },

  methods: {
    showDelDialog(item) {
      this.occurrence = item;
      this.dialogDel = true;
    },

    closeDelDialog() {
      this.dialogDel = false;
    },

    showUpdateDialog(item) {
      this.occurrence = item;
      this.dialogUpdate = true;
    },

    closeUpdateDialog() {
      this.dialogUpdate = false;
    },
  },

  watch: {
    items(newValue) {
      this.occurrences = newValue;
    },
    academicLifeId(newId) {
      this.academicLife = newId;
    },
  },
};
</script>

<style scoped>
.altura {
  max-height: 100px;
  overflow: auto;
}
</style>