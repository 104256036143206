var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.flags.length > 0
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [_c("v-col", [_c("h3", [_vm._v("Situação")]), _c("hr")])],
                1
              ),
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [_c("div", [_vm._v("Legenda")]), _c("v-spacer")],
                        1
                      ),
                      _vm._l(_vm.legends, function(l) {
                        return _c(
                          "v-col",
                          { key: l.id, attrs: { cols: "2" } },
                          [
                            _c(
                              "div",
                              { staticClass: "caption" },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { color: l.color, size: "22" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "black", small: "" } },
                                      [_vm._v(_vm._s(l.span))]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(l.message) + " ")
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mt-8",
                  attrs: { align: "center", justify: "center" }
                },
                _vm._l(_vm.flags, function(i) {
                  return _c(
                    "v-col",
                    { key: i.id, staticClass: "flag" },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c("p", { staticClass: "caption" }, [
                            _vm._v(_vm._s(i.title))
                          ])
                        ]
                      ),
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-avatar",
                                          _vm._g(
                                            {
                                              staticClass: "custom-avatar",
                                              attrs: {
                                                color: i.color,
                                                size: "60"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "black",
                                                  large: ""
                                                }
                                              },
                                              [_vm._v(_vm._s(i.span))]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(i.message))])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }