<template>
  <div>
    <v-row align="center" justify="center">
      <v-col>
        <StudentInfo :academicCode="academicCode"></StudentInfo>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Flags :guidance="'false'"></Flags>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <AcademicLife></AcademicLife>
      </v-col>
    </v-row>

    <v-row v-show="isAdmin || isStaff">
      <v-col>
        <Occurrences></Occurrences>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <CourseBreak></CourseBreak>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Subjects></Subjects>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SubjectsValidations></SubjectsValidations>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Qualification></Qualification>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <EnglishProficiency></EnglishProficiency>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Guidance></Guidance>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Ped></Ped>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as storage from '@/pages/auth/storage'

import Ped from "@/components/student/ped/Ped";
import Flags from "@/components/student/flags/Flags";
import Guidance from "@/components/student/guidance/Guidance";
import Subjects from "@/components/student/subjects/Subjects";
import CourseBreak from "@/components/student/course_break/CourseBreak";
import StudentInfo from "@/components/student/student_info/StudentInfo";
import AcademicLife from "@/components/student/academic_life/AcademicLife";
import Qualification from "@/components/student/qualification/Qualification";
import EnglishProficiency from "@/components/student/english_proficiency/EnglishProficiency";
import SubjectsValidations from "@/components/student/subjects_validations/SubjectsValidations";
import Occurrences from "@/components/student/occurrence/Occurrences";

export default {
  name: "Student",
  props: [],
  components: {
    Ped,
    Flags,
    Guidance,
    Subjects,
    CourseBreak,
    Occurrences,
    StudentInfo,
    AcademicLife,
    Qualification,
    EnglishProficiency,
    SubjectsValidations,
  },
  data() {
    return {
      user: null,
      student: {},
      academicCode: null,
    };
  },

  created() { 
    this.student = this.$route.params.student;
    this.academicCode = this.$route.params.academic_code;
    this.user = storage.getLocalData().user;
  },

  computed: {
    isAdmin(){
      return this.user.roles.includes("admin");
    },
    isStaff(){
      return this.user.roles.includes("staff");
    }
  },
};
</script>

<style>
</style>