<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Proficiência em Língua Estrangeira</h3>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="englishProficiency"
            item-key="test_date"
            hide-default-footer
            no-data-text="Não há dados disponíveis para a consulta"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.code }}</td>
              <td class="text-xs-left">{{ props.item.proficiency_date }}</td>
              <td class="text-xs-left">{{ props.item.situation }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/utils/formatDate'

export default {
  name: 'english_proficiency',
  data() {
    return {
      headers: [
        { sortable: false, text: "Código", value: "code", align: "left" },
        {
          sortable: false,
          text: "Data do Exame",
          value: "proficiency_date",
          align: "left"
        },
        { sortable: false, text: "Situação", value: "situation", align: "left" }
      ],
      englishProficiency: []
    };
  },
  watch: {
    getStudent(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
        acal => acal.situation === "Ativo - Curso em Andamento"
      );
      this.getAsyncEnglishProficiency(academicLife[0].id);
    },

    getEnglishProficiency(newValue) {
      this.englishProficiency = [];
      newValue.map(e => {
        this.englishProficiency.push({
             situation: e.situation,
             code: e.code,
             date_raw: e.proficiencyDate,
             proficiency_date: formatDate.dmy(e.proficiencyDate)
           })
      });
    }
  },

  methods: {
    ...mapActions("englishProficiency", ["ActionGetEnglishProficiency"]),

    async getAsyncEnglishProficiency(academicLifeId) {
      try {
        await this.ActionGetEnglishProficiency(academicLifeId);
      } catch (err) {
        console.log("Não foi possível buscar os exames de proficiência em inglês");
      }
    }
  },

  computed: {
    ...mapGetters("student", ["getStudent"]),
    ...mapGetters("englishProficiency", ["getEnglishProficiency"])
  }
};
</script>
