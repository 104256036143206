<template>
  <div v-if="flags.length > 0">
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Situação</h3>
          <hr />
        </v-col>
      </v-row>

      <v-card outlined>
        <v-row class="justify-center">
          <v-col cols="2">
            <div>Legenda</div>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="2" v-for="l in legends" :key="l.id">
            <div class="caption">
              <v-avatar :color="l.color" size="22" class="mb-1">
                <v-icon color="black" small>{{ l.span }}</v-icon>
              </v-avatar>
              {{ l.message }}
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-row align="center" justify="center" class="mt-8">
        <v-col v-for="i in flags" v-bind:key="i.id" class="flag">
          <v-row align="center" justify="center">
            <p class="caption">{{ i.title }}</p>
          </v-row>
          <v-row align="center" justify="center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-avatar :color="i.color" size="60" v-on="on" class="custom-avatar">
                  <v-icon color="black" large>{{ i.span }}</v-icon>
                </v-avatar>
              </template>
              <span>{{ i.message }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { statusInterpreter } from "@/utils/statusInterpreter";

export default {
  name: "flags",
  props: ["guidance"],
  data() {
    return {
      flags: [],
      facultyGuidance: false,
      legends: [
        {
          id: 0,
          color: statusInterpreter.cor(0),
          message: "OK",
          span: statusInterpreter.span(0),
        },
        {
          id: 2,
          color: statusInterpreter.cor(2),
          message: "Informação",
          span: statusInterpreter.span(2),
        },
        {
          id: 3,
          color: statusInterpreter.cor(4),
          message: "Atenção",
          span: statusInterpreter.span(4),
        },
        {
          id: 4,
          color: statusInterpreter.cor(6),
          message: "Problema",
          span: statusInterpreter.span(6),
        },
      ],
    };
  },

  created() {
    this.facultyGuidance = this.guidance;
  },
  computed: {
    ...mapGetters("student", ["getStudent"]),
    ...mapGetters("flags", ["getFlags"]),
  },
  methods: {
    ...mapActions("flags", ["ActionSetFlags"]),

    arrayMin(arr) {
      return arr.reduce(function (p, v) {
        return p.codigo < v.codigo ? p : v;
      });
    },
  },
  watch: {
    getStudent(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
         acal => acal.situation === "Ativo - Curso em Andamento" && acal.course.code != '99'
      );

      let rulesByAcademicLife =
        newValue.rulesByAcademicLife[academicLife[0].id];

      if (rulesByAcademicLife !== null) {
        this.ActionSetFlags(rulesByAcademicLife);
      }
    },

    getFlags(newValue) {
      this.flags = [];
      let i = 0;
      newValue.map((flag) => {
        // captura a mensagem que tem o código menor dentro do array
        const mensagem = this.arrayMin(flag.mensagens);

        if (flag.tipo !== "eqg") {
          if (flag.tipo !== "orientacao") {
            // Insere no array um objeto flag
            this.flags.push({
              id: i++,
              title: statusInterpreter.title(flag.tipo),
              span: statusInterpreter.span(mensagem.codigo),
              color: statusInterpreter.cor(mensagem.codigo),
              message: statusInterpreter.desc(mensagem.descricao),
            });
          } else {
            if (this.facultyGuidance !== "true") {
              // Insere no array um objeto flag
              this.flags.push({
                id: i++,
                title: statusInterpreter.title(flag.tipo),
                span: statusInterpreter.span(mensagem.codigo),
                color: statusInterpreter.cor(mensagem.codigo),
                message: statusInterpreter.desc(mensagem.descricao),
              });
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.flag {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}
fieldset {
  width: 40%;
}
</style>