var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            [_c("StudentInfo", { attrs: { academicCode: _vm.academicCode } })],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [_c("v-col", [_c("Flags", { attrs: { guidance: "false" } })], 1)],
        1
      ),
      _c("v-row", [_c("v-col", [_c("AcademicLife")], 1)], 1),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAdmin || _vm.isStaff,
              expression: "isAdmin || isStaff"
            }
          ]
        },
        [_c("v-col", [_c("Occurrences")], 1)],
        1
      ),
      _c("v-row", [_c("v-col", [_c("CourseBreak")], 1)], 1),
      _c("v-row", [_c("v-col", [_c("Subjects")], 1)], 1),
      _c("v-row", [_c("v-col", [_c("SubjectsValidations")], 1)], 1),
      _c("v-row", [_c("v-col", [_c("Qualification")], 1)], 1),
      _c("v-row", [_c("v-col", [_c("EnglishProficiency")], 1)], 1),
      _c("v-row", [_c("v-col", [_c("Guidance")], 1)], 1),
      _c("v-row", [_c("v-col", [_c("Ped")], 1)], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }