<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Exame de Qualificação</h3>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="qualifications"
            item-key="test_date"
            hide-default-footer
            no-data-text="Não há dados disponíveis para a consulta"
           
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.type }}</td>
              <td class="text-xs-left">{{ props.item.test_date }}</td>
              <td class="text-xs-left">{{ props.item.situation }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "qualification_exam",
  data() {
    return {
      show: false,
      qualifications: [],
      headers: [
        {
          sortable: false,
          text: "Tipo de Exame",
          value: "type",
          align: "left"
        },
        {
          sortable: false,
          text: "Data do Exame",
          value: "test_date",
          align: "left"
        },
        {
          sortable: false,
          text: "Situação",
          value: "situation",
          align: "left"
        }
      ]
    };
  },
  watch: {
    getStudent(newValue) {
      let academicLifes = newValue.academicLifes;
      let academicLife = academicLifes.filter(
        acal => acal.situation === "Ativo - Curso em Andamento"
      );

      this.getAsyncQualification(academicLife[0].id);
    },

    getQualification(newValue) {
      this.qualifications = [];
      newValue.map(q => {
        this.qualifications.push({
          situation: q.situation,
          test_date: formatDate.dmy(q.testDate),
          type: q.type
        });
      });
    }
  },

  computed: {
    ...mapGetters("student", ["getStudent"]),
    ...mapGetters("qualification", ["getQualification"])
  },

  methods: {
    ...mapActions("qualification", ["ActionGetQualification"]),

    async getAsyncQualification(academicLifeId) {
      try {
        await this.ActionGetQualification(academicLifeId);
      } catch (err) {
        console.log("Não foi possível buscar os exames de qualificação");
      }
    }
  }
};
</script>
