<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Convalidações</h3>
           <hr>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="validations"
            v-bind:search="search"
            item-key="code"
            hide-default-footer
            no-data-text="Não há dados disponíveis para a consulta"
            v-bind:loading="showLoading"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.code }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.start_date }}</td>
              <td class="text-xs-left">{{ props.item.score }}</td>
              <td class="text-xs-left">{{ props.item.institute }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SubjectValidation",
  props: ["academicLifeId"],
  data() {
    return {
      show: false,
      showLoading: false,
      validations: [],
      search: "",
      headers: [
        { sortable: true, text: "Código", value: "code", align: "left" },
        { sortable: true, text: "Disciplina", value: "name", align: "left" },
        { sortable: true, text: "Cursada em", value: "start_date", align: "left" },
        { sortable: true, text: "Conceito", value: "score", align: "left" },
        { sortable: true, text: "Instituto", value: "institute", align: "left" }
      ]
    };
  },

  created() {

  },

  computed: {
    ...mapGetters("student", ["getStudent"]),
    
    },
    watch: {
      // sempre que a pergunta mudar, essa função será executada
      getStudent: function(newValue) {
        this.validations = [];
        let academicLifes = newValue.academicLifes;
        let academicLife = academicLifes.filter(acal => acal.situation === 'Ativo - Curso em Andamento');
        let validations = academicLife[0].convalidations;
        validations.map(v=>{
            this.validations.push(
              {
                code: v.subject.code,
                name: v.subject.name,
                start_date: (v.period == 5 || v.period == 6)? v.year:(v.year + '/' + v.period + 's'),
                score: v.score,
                institute: v.subject.institute
              }
            ); 
        });
      }
    },

  methods: {
  
  }
};
</script>

<style scoped>
</style>
